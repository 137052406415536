.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
}

.card {
  border-radius: 20px;
  padding: 5px;
  filter: drop-shadow(0px 3px 15px rgba(0, 0, 0, 0.1));
  position: relative;
  cursor: pointer;
  overflow: hidden;
  height: 300px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  &__description {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(217, 215, 215, 0.6);
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 60px 20px 30px;
    box-sizing: border-box;
    text-align: center;
    color: rgb(0, 0, 0);
    transform: translateY(25px);
    transition: transform 0.6s;

    &:hover {
      opacity: 1;
      border: 1px solid brown;
      transform: translateY(0px);
    }

    .links {
      display: flex;
      gap: 60px;

      .link-github,
      .paperClip {
        width: 50px;
        height: 50px;
        color: black;
      }

      .link-github:hover {
        background-color: #fff;
      }
      .paperClip:hover {
        color: #b38456;
      }

      .vibrate {
        animation: vibrate 0.5s linear 5;
        animation-delay: 2s;
      }
    }

    .position {
      text-align: right;
      width: 100%;

      &__plus {
        border: 1px solid;
        padding: 10px;
        border-radius: 3px;
        font-size: 1em;
        border: 0;
        color: #fff;
        background-color: #b38456;

        &:hover {
          background-color: black;
        }
      }
    }
  }
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog {
  border: none;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  width: 500px;
  text-align: center;
  z-index: 1001;
  background-color: white;

  h3 {
    font-size: 24px;
    color: #b38456;
    font-weight: bold;
    border-bottom: 1px solid #ac7b49;
  }

  p {
    font-size: 18px;
  }

  img {
    width: 160px;
  }

  &__tools {
    justify-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

.dialog-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

@keyframes vibrate {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-4px);
  }
  50% {
    transform: translateY(4px);
  }
  75% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(0);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 24px;
    margin-bottom: 30px;
    gap: 0;
  }

  .card {
    width: 100%;
    height: 170px;
    margin: 0;

    img {
      width: 90%;
      height: 90%;
    }

    &__description {
      padding: 24px 20px 20px 20px;
      transform: none;
      transition: none;

      .links {
        .link-github,
        .paperClip {
          width: 40px;
          height: 40px;
        }
      }

      .position {
        &__plus {
          padding: 8px;
        }
      }
    }

    &.active &__description {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .dialog {
    padding: 20px;

    img {
      width: 100px;
      height: auto;
      max-height: 20vh;
      object-fit: contain;
    }
  }
}



@media (max-width: 767px) {
  .cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0;
  }

  .card {
    width: 100%;
    height: 170px;
    margin: 0;

    img {
      width: 90%;
      height: 90%;
    }

    &__description {
      padding: 24px 20px 20px 20px;
      transform: none;
      transition: none;

      .links {
        .link-github,
        .paperClip {
          width: 40px;
          height: 40px;
        }
      }

      .position {
        &__plus {
          padding: 8px;
        }
      }
    }

    &.active &__description {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .dialog {
    padding: 20px;

    img {
      width: 100px;
      height: auto;
      max-height: 20vh;
      object-fit: contain;
    }
  }
}
