form {
  display: flex;
  flex-direction: column;
  padding: 40px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 80%;
  margin: 40px;
  background-color: #f9f9f9;
  text-align: left;

  .error {
    margin-top: 0;
    color: red;
  }
}

.group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.userInfos, .messageContainer {
  display: flex;
  flex-direction: column;
  width: 48%;
}

label {
  margin-bottom: 10px;
  font-size: 1.2em;
}

input, textarea {
  width: 100%;
  padding: 15px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  outline: none;
}

textarea {
  resize: vertical;
  height: 258px;

  &::placeholder {
    font-size: 16px;
  }
}

.btn {
  align-self: center;
  width: 200px;
  padding: 15px;
  margin-top: 12px;
  background-color: #b38456;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: black;
    color: #fff;
  }
}


@media (min-width: 768px) and (max-width: 1024px) {
  form {
    padding: 24px;
  }

  .group {
    justify-content: normal;
    flex-direction: column;
  }

  .userInfos, .messageContainer {
    width: 100%;
  }

  textarea {
    height: 230px;
  }

  .btn {
    align-self: center;
    width: 200px;
    padding: 15px;
    margin-top: 12px;
    background-color: #b38456;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: black;
      color: #fff;
    }
  }
}


@media (max-width: 767px) {
  form {
    padding: 16px;
    width: 100%;
    margin-top: 30px;

    .error {
      margin-top: 0;
    }
  }

  .group {
    flex-direction: column;
  }

  .userInfos, .messageContainer {
    width: 100%;
  }

  label {
    font-size: 1em;
  }

  input, textarea {
    padding: 12px;
    margin-bottom: 4px;
  }

  textarea {
    height: 120px;
  }

  .btn {
    align-self: center;
    width: 200px;
    padding: 15px;
    margin-top: 12px;
    background-color: #b38456;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: black;
      color: #fff;
    }
  }
}
