.banner {
  background-color: #b38456;
  display: flex;
  align-items: normal;
  padding-top: 240px;

  &__description {
    background-color: #b38456;
    padding: 0;
    text-align: left;

    .titles {
      position: relative;
      height: 2em;
    }

    h2 {
      font-size: 7em;
      color: #fff;
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      opacity: 0;
      transform: translateY(100%);
    }

    .fade-in.visible {
      opacity: 1;
      transform: translateY(0);
      animation: stay-visible 4s ease-in-out forwards;
    }

    .arrow {
      animation: arrowMoving 1.5s infinite;
      transform: translateY(-30%);
    }

    .fa-arrow-down {
      font-size: 5em;
      color: #fff;
    }
  }

  &__about-me{
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 30px;
      width: 56%;
    }

    .circle {
      width: 400px;
      height: 400px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }
  }
}

@keyframes stay-visible {
  0%, 50% {
    opacity: 1;
    transform: translateY(-80%);
  }
  60% {
    opacity: 0;
    transform: translateY(-50%);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

@keyframes arrowMoving {
  0%, 20% {
    opacity: 1;
    transform: translateY(-20%);
  }
  50%, 80% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}


@media (min-width: 768px) and (max-width: 1024px) {
  .banner {
    background-color: #b38456;
    display: flex;
    align-items: normal;
    padding-top: 80px;

    &__description {
      .titles {
        height: 8em;
      }

      h2 {
        font-size: 4em;
      }

      .fa-arrow-down {
        font-size: 3em;
      }
    }

    &__about-me{

      .circle {
        width: 200px;
        height: 200px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
          height: auto;
          object-fit: contain;
        }
      }
    }
  }

  @keyframes arrowMoving {
    0%, 20% {
      opacity: 1;
      transform: translateY(60%);
    }
    50%, 80% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 0;
      transform: translateY(0);
    }
  }
}


@media (max-width: 767px) {
  .banner {
    padding-top: 80px;

    &__description {
      gap: 100px;
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 2.5em;
      }

      .fa-arrow-down {
        font-size: 3em;
      }
    }

    &__about-me{
      display: flex;
      position: relative;
      align-items: center;
      gap: 20px;

      p {
        font-size: 18px;
        width: 64%;
      }

      .circle {
        width: 100px;
        height: 100px;

        img {
          object-fit: cover;
          display: block;
          height: auto;
          max-height: 20vh;
          object-fit: contain;
        }
      }
    }
  }
}
