*,
*::before,
*::after {
  box-sizing: border-box;
}

#root {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0;
  background-color: #b38456;
}

main {
  flex: 1;
  padding-top: 80px;
}

img {
  height: fit-content;
}

section {
  height: 100vh;
  scroll-snap-align: start;
  padding: 0 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  margin: 0;
}

h4 {
  font-size: 20px;
  margin-bottom: 0;
}

@import "./components/header";
@import "./components/banner";
@import "./components/projects";
@import "./components/skills";
@import "./components/contact";
@import "./components/footer";
@import "./pages/home";


@media (min-width: 768px) and (max-width: 1024px) {
  section {
    padding: 0 50px;

    h1 {
      font-size: 34px;
    }
  }
}


@media (max-width: 767px) {
  section {
    padding: 0 20px;
  }
}
