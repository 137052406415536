.skills__cards {
  display: flex;
  gap: 20px;
  margin: 44px;
  width: 80%;
}

.card-skill {
  width: 250px;
  height: 208px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid brown;
  border-radius: 8px;
  padding: 0 12px;
  text-align: center;

  span {
    font-size: 24px;
    color: #af6e2d;
    font-weight: bold;
    border-bottom: 1px solid #ac7b49;
    border-right: 1px solid #ac7b49;
  }
}

.tools {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  &__icons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    & img {
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .skills__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 20px;
    width: 100%;
    justify-items: center;
  }

  .card-skill {
    width: 170px;
    height: 116px;
    padding: 0 16px;

    img {
      width: 180px;
    }
  }

  .tools {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    &__icons {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-items: center;

      & > img:first-of-type {
        width: 150px;
      }
      & > img:nth-of-type(2) {
        width: 150px;
      }
      & > img:nth-of-type(3) {
        width: 60px;
      }
      & > img:nth-of-type(4) {
        width: 80px;
      }
    }
  }
}


@media (max-width: 767px) {
  .skills__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 20px;
    width: 100%;
  }

  .card-skill {
    width: 100px;
    height: 100px;
    padding: 0 8px;

    span {
      font-size: 16px;
      margin: 0;
    }

    img {
      width: 100px;
    }
  }

  .tools {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    &__icons {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-items: center;

      & > img:first-of-type {
        width: 100px;
      }
      & > img:nth-of-type(2) {
        width: 90px;
      }
      & > img:nth-of-type(3) {
        width: 40px;
      }
      & > img:nth-of-type(4) {
        width: 50px;
      }
    }
  }
}
