.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 108px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  transition: background-color 0.3s ease;
  background-color: transparent;

  &.scrolled {
    background-color: rgb(255 255 255 / 47%);
  }

  &__logo {
    a, h1, p {
      color: #fff;
    }

    h1, p {
      margin: 0;
    }

    p {
      font-size: 18px;
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__title {
    text-align: center;
    color: white;
    font-size: 24px;
    margin-left: 140px;

    a {
      text-decoration: none;
      color: #fff;
    }
    .new-title {
      color: black;
      cursor: pointer;
    }
  }

  &__links {
    display: flex;

    & a {
      margin-left: 20px;
      text-decoration: none;
      border: 1px solid white;
      padding: 16px 20px;
      border-radius: 28px;
      font-size: 20px;
      transition: background-color 0.3s, color 0.3s;
      position: relative;
      overflow: hidden;
    }

    .black-links {
      border: 1px solid black;
      color: black;
    }

    &--projects.black-links,
    &--skills.black-links,
    &--contact.black-links {
      &::before {
        background-color: black;
      }
      &::after {
        color: #fff;
      }
      &:hover::after {
        color: #fff;
      }
    }

    &--projects,
    &--skills,
    &--contact {
      color: white;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        background-color: white;
        z-index: -1;
        transition: height 0.3s ease-in-out;
      }

      &:hover::before {
        height: 100%;
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scaleY(0);
        transform-origin: bottom;
        color: black;
        transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
      }

      &:hover::after {
        transform: translate(-50%, -50%) scaleY(1);
        color: black;
      }
    }

    &--projects::after {
      content: 'Projects';
    }

    &--skills::after {
      content: 'Skills';
    }

    &--contact::after {
      content: 'Contact';
    }
  }

  .dropdown {
    display: none;
  }
}


@media (min-width: 768px) and (max-width: 1024px) {
  .navbar {
    padding: 0 50px;

    &__logo {
      h1 {
        font-size: 18px;
      }

      p {
        font-size: 16px;
      }
    }

    &__title {
      font-size: 20px;
      margin-left: 90px;
    }

    &__links {
      & a {
        margin-left: 8px;
        padding: 12px 16px;
        font-size: 16px;
      }
    }
  }
}


@media (max-width: 767px) {
  .navbar {
    padding: 0 20px;
    flex-direction: row-reverse;

    &__logo {
      h1 {
        font-size: 16px;
      }

      p {
        font-size: 12px;
      }
    }

    &__title {
      font-size: 18px;
      margin: 0 0 0 -64px;

      .new-title {
        margin: 0 0 0 -70px;
      }
    }

    &__links {
      display: none;
    }

    .dropdown {
      display: block;
      position: relative;

      #menu {
        font-size: 32px;
        border: 0;
        background-color: transparent;
        padding: 0;
      }

      .bars {
        color: white;
      }

      .change-color {
        color: black;
      }

      .submenu {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        margin-top: 1rem;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-size: 20px;
        border-radius: 4px;
        left: 0;
        top: 100%;
        background-color: black;
        padding: 16px;
        transition: opacity 0.3s ease, visibility 0.3s ease;
        border-top-left-radius: 0px;
        border-top-right-radius: 36px;

        a {
          text-decoration: none;
          font-size: 28px;
          color: #fff;
        }
      }
      .submenu.open {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
