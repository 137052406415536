section {
  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 26px;
  }
}

.projects,
.contact {
  background-color: #f1ebe4;
}

.skills {
  background-color: #dfede2;
  padding: 0;
}

.contact {
  background-color: #f1ebe4;
  height: 70vh;
}


@media (min-width: 768px) and (max-width: 1024px) {

  section {
    h1 {
      font-size: 34px;

    }
  }

  .projects {
    height: 80vh;

    h1 {
      margin-top: 12px;
    }
  }

  .skills {
    background-color: #dfede2;
    padding: 0 50px;
    height: 70vh;
  }

  .contact {
    height: 90vh;
  }
}


@media (max-width: 767px) {
  section {
    h1 {
      font-size: 28px;
    }

    h2 {
      font-size: 24px;
    }
  }

  .banner {
    height: 92vh;
  }

  .projects,
  .skills,
  .contact {
    height: auto;
    padding: 36px 20px;
  }
}
